import * as Sentry from '@sentry/vue'

import genAbilityHashId from './genAbilityHashId.js'

export const DAMAGE_ID_BOMB = 'bomb'
export const DAMAGE_ID_MELEE = 'melee'

export default function genDamageId({ damage_type, damage_item }, agent) {
  switch (damage_type) {
    case 'Ability':
      return genAbilityHashId(agent, damage_item)
    case 'Bomb':
      return DAMAGE_ID_BOMB
    case 'Melee':
      return DAMAGE_ID_MELEE
    case 'Weapon':
      return damage_item.toLowerCase()
    default:
      if (damage_type != null || damage_item != null) {
        console.error(`Unhandled damage type ${damage_type} - ${damage_item}`)
        Sentry.captureException(new Error(`Unhandled damage type ${damage_type} - ${damage_item}`))
      }
      return null
  }
}
